<template>
<div class="instagram-large-item">
      <div class="instagram-large-container row">
            <b-col md="8" >
                  <img :src="fullwidth.src" :width="fullwidth.width" :height="fullwidth.height" class="w-100" />
            </b-col>
            <b-col md="4">
                  <div class="pl-3 pr-3 pt-sm-2">
                  <div class="mb-2 instagram-caption">
                        <span class="font-weight-bold">{{username}}</span> <span v-html="caption"></span>
                  </div>
                  <div>
                        <span class="mr-2">
                              <inline-svg :src="svg('like.svg')" /> {{likes}}
                        </span>
                        <span class="mr-2">
                              <inline-svg :src="svg('comment.svg')" /> {{comments}}
                        </span>
                  </div>
                  <div class="text-center mt-4">
                        <a class="btn btn-primary" target="_blank" :href="link">Visa på instagram</a>
                  </div>
                  </div>
            </b-col>
      </div>
</div>
</template>

<script>
export default {
      name: 'InstagramLargeItem',
      components: {},
      props: ["item"],
      data() {
            return {
                  instagram: this.item
            };
      },
      computed: {
            link()
            {
                  return "https://www.instagram.com/p/"+ this.instagram.shortcode+"/";
            },
            username() {
                  return this.$instagram.username(this.item);
            },
            fullwidth() {
                  return this.$instagram.fullwidth(this.item, 0);
            },
            likes() {
                  return this.$instagram.likes(this.item);
            },
            comments() {
                  return this.$instagram.comments(this.item);
            },
            caption() {
                  return this.$instagram.caption(this.item);
            },
            timetaken() {
                  var adate = this.$instagram.timetaken(this.item);
                  var datestring = this.$date.dateNowWithoutTime(adate);
                  return this.$date.stringToReadableFormat(datestring);
            }
      },
      methods: {
            svg(path) {
                  return this.$images.getSvgUrl(path);
            },
            callback(ishover) {
                  if (ishover) {
                        this.showoverlay = true;
                  } else {
                        this.showoverlay = false;
                  }
            }
      }
};
</script>
